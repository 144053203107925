import React from 'react'
import "./Expressive.css"

const ExpressiveAnimation = () => {
  return (
    <div className="logoEXP">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
  
        x="0px"
        y="0px"
        viewBox="0 0 792 612"
   
      >
        <path
          className="spiral-reveal grey-spiral"
          d="M484.4,270.6c88.1-51.9,140.7-104.7,46.6-109.6c-94-4.9-245.3,50.4-254.4,158s500.1,10.5,480.2-188.7
      C741.7,15.3,361.5,22.5,149.1,226.1S72.8,560.2,239,554.7c166.3-5.6,425.6-55.3,505.9-137.4"
        />
        <path c
          className="spiral-reveal orange-spiral"
          d="M484.4,270.6c88.1-51.9,140.7-104.7,46.6-109.6c-94-4.9-245.3,50.4-254.4,158s500.1,10.5,480.2-188.7
      C741.7,15.3,361.5,22.5,149.1,226.1S72.8,560.2,239,554.7c166.3-5.6,425.6-55.3,505.9-137.4"
        />
        <path
          className="spiral-reveal purple-spiral"
          d="M484.4,270.6c88.1-51.9,140.7-104.7,46.6-109.6c-94-4.9-245.3,50.4-254.4,158s500.1,10.5,480.2-188.7
      C741.7,15.3,361.5,22.5,149.1,226.1S72.8,560.2,239,554.7c166.3-5.6,425.6-55.3,505.9-137.4"
        />
        <clipPath id="clip-spiral">
          <path
            className="st1"
            d="M775.3,115.2C742.4,22.6,542.7,25,376,85.8C177.7,151-21.4,322.6,18.1,462.5c37,99.8,222.8,124.2,398.5,96.2
        c21.5-6.8,16.1-29.2,0-25.4c-11.2,2-301.4,44-357.8-88.2C16,323.4,203.9,155.9,380,101c176.1-56,328.3-49.2,355.2,24.8
        C771.5,285,333.1,415.9,295.9,311.4c-11.1-41.9,43.8-82.2,56.1-92.2c0,0,2-11.8,10.9-28c-60.7,29.1-120,90.9-107,133.6
        C295.4,455.5,822.9,305.1,775.3,115.2z M512.2,537.3c43.9-11.1,74.8-21.4,110.8-37.4c16.8-8,10-28.1-13.4-21.3
        c-17.7,6.8-62.2,25-102.8,34.7C488.3,518.2,486.3,542.4,512.2,537.3z M717.8,419.7c-8.8,6.1-19.7,12.1-25.4,16.5
        c-9.5,7.4,4,22.5,17.4,15.5c7.5-3.1,17.3-10.4,22.7-14.7C745.3,424.3,727.7,411.9,717.8,419.7L717.8,419.7z M545.2,246.9
        c-5.8,3.7-11.8,7.1-18,10.3c-11,5.6-22.6,10.2-34.1,13.5l-4-16.9c15.9-6.3,30.5-13.3,42-20.5c3.3-2.1,6.3-4.2,9-6.3
        c2.1-1.6,4-3.2,5.7-4.8c1.8-1.7,3.5-3.5,4.9-5.3c16.6-20.8,10.1-42.1-26-45.4c-6.7-8.2-14.7-15.2-23.7-20.9
        c9.1-0.3,18.5-0.3,28.2-0.1C633.4,150.5,603.3,209.9,545.2,246.9z"
          />
        </clipPath>
        <path
          className="purple-circle"
          d="M531.1,232.3c0,0.3,0,0.6,0,0.8c-0.1,8.3-1.4,16.4-3.8,23.9c-10.5,32.7-41.1,56.4-77.3,56.4
      c-26.1,0-49.3-12.3-64.1-31.4c-3.8-4.9-7-10.2-9.6-15.9c-4.7-10.3-7.4-21.8-7.4-33.9c0-7.4,1-14.5,2.8-21.3h0
      c4.6-16.9,14.5-31.7,27.9-42.3c13.8-11,31.4-17.6,50.4-17.6c6.1,0,12,0.7,17.8,1.9c13.5,3,25.8,9.4,35.8,18.2
      c12.3,10.8,21.4,25.4,25.4,41.8C530.3,219.3,531.1,225.7,531.1,232.3z
      M540.1,226.9c-2.7,2.1-5.8,4.2-9,6.3c-11.5,7.3-26.1,14.2-42,20.5c-34.1,13.5-74.3,24-103.4,28.4
      c-3.8,0.6-7.5,1-10.9,1.4l-6.2-16.6c2.5-0.2,5-0.4,7.4-0.6c65.8-6.4,122.9-34.9,152.7-53.1c1.5-0.9,2.9-1.8,4.3-2.7L540.1,226.9z"
        />
        <clipPath id="top-clip-stripe">
          <path
            className="st2"
            d="M538.8,223.8c0.8,1.8,0.2,4-1.4,5.2c-2,1.4-4.1,2.8-6.3,4.2c-11.5,7.3-26.1,14.2-42,20.5
        c-34.1,13.5-74.3,24-103.4,28.4c-2.2,0.3-4.3,0.6-6.4,0.9c-2.6,0.3-5.1-1.2-6.1-3.7l-2-5.4c-1.3-3.5,1.1-7.2,4.7-7.6l0.1,0
        c65.8-6.4,122.9-34.9,152.7-53.1c0,0,0,0,0,0c2.2-1.4,5.2-0.4,6.2,2L538.8,223.8z"
          />
        </clipPath>
        <clipPath id="orange-grey-clip-stripe">
          <path
            className="st2"
            d="M540.1,226.9c-2.7,2.1-5.8,4.2-9,6.3c-11.5,7.3-26.1,14.2-42,20.5c-34.1,13.5-74.3,24-103.4,28.4
        c-3.8,0.6-7.5,1-10.9,1.4l-6.2-16.6c2.5-0.2,5-0.4,7.4-0.6c65.8-6.4,122.9-34.9,152.7-53.1c1.5-0.9,2.9-1.8,4.3-2.7L540.1,226.9z"
          />
        </clipPath>
        <clipPath id="clip-circle">
          <path
            className="masking-circle"
            d="M531.1,232.3c0,0.3,0,0.6,0,0.8c-0.1,8.3-1.4,16.4-3.8,23.9c-10.5,32.7-41.1,56.4-77.3,56.4
        c-26.1,0-49.3-12.3-64.1-31.4c-3.8-4.9-7-10.2-9.6-15.9c-4.7-10.3-7.4-21.8-7.4-33.9c0-7.4,1-14.5,2.8-21.3h0
        c4.6-16.9,14.5-31.7,27.9-42.3c13.8-11,31.4-17.6,50.4-17.6c6.1,0,12,0.7,17.8,1.9c13.5,3,25.8,9.4,35.8,18.2
        c12.3,10.8,21.4,25.4,25.4,41.8C530.3,219.3,531.1,225.7,531.1,232.3z"
          />
        </clipPath>
        <path
          className="stripe-reveal orange-stripe"
          d="M367.6,278.5c61.5-9.6,146.3-42.1,174.8-64.8"
        />
        <path
          className="stripe-reveal grey-stripe"
          d="M367.6,278.5c61.5-9.6,146.3-42.1,174.8-64.8"
        />
        <path
          className="stripe-reveal purple-stripe"
          d="M367.6,278.5c61.5-9.6,146.3-42.1,174.8-64.8"
        />
      </svg>
    </div>
  )
}

export default ExpressiveAnimation