import React from 'react'
import ContactDropdown from './ContactDropdown'

const ContactPage = () => {
  return (
    <div className='ContactPage'>
      <ContactDropdown />
    </div>
  )
}

export default ContactPage